import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				PureClean
			</title>
			<meta name={"description"} content={"Там, где сияет ваш автомобиль"} />
			<meta property={"og:title"} content={"PureClean"} />
			<meta property={"og:description"} content={"Там, где сияет ваш автомобиль"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
		</Helmet>
		<Components.Header background="#24165a">
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link" />
		</Components.Header>
		<Section padding="80px 0 0 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text font="--headline3" color="--darkL2" max-width="90%">
					Более десяти лет PureClean Car Wash является самым лучшим выбором как для автолюбителей, так и для обычных водителей. Используя самое современное оборудование и экологически чистые чистящие решения, мы гарантируем безупречную обработку, которая не только улучшает визуальную привлекательность, но и сохраняет целостность и производительность вашего автомобиля.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="center"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10:50:58.140Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="contain"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10%3A50%3A58.140Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10%3A50%3A58.140Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10%3A50%3A58.140Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10%3A50%3A58.140Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10%3A50%3A58.140Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10%3A50%3A58.140Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/1-1.jpg?v=2024-06-14T10%3A50%3A58.140Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Мы стремимся предоставить исключительные услуги и ценность для всех наших клиентов. Наши передовые методы разработаны для обеспечения тщательной очистки, улучшающей внешний вид вашего автомобиля, защищая его долгосрочную ценность. Доверьтесь нам, чтобы мы обращались с вашим автомобилем, как с нашим собственным.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="linear-gradient(0deg,rgba(180, 173, 173, 0.38) 0%,#e8e4f4 99%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1500067318459-77a2667d9c54?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="85%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Там, где сияет ваш автомобиль
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						В PureClean Car Wash мы верим, что каждый автомобиль заслуживает того, чтобы сиять! Независимо от того, готовитесь ли вы к особому событию, или просто хотите побаловать свою поездку, наша преданная команда здесь, чтобы предоставить самые качественные услуги по мойке и украшению деталей в городе. От семейных до спортивных автомобилей наш подход адаптирован к потребностям вашего автомобиля с безупречным вниманием к деталям.
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Качество и точность
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Мы используем опытное управление и используем продукты высокого класса, чтобы ваш автомобиль выглядел как можно лучше.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Быстрое и эффективное обслуживание
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Мы предлагаем быстрое время выполнения без ущерба для качества, гарантируя, что ваш автомобиль будет готов, когда вам это нужно.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Экологически чистые решения
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Наше обязательство распространяется на окружающую среду с помощью биоразлагаемых и экологически безопасных продуктов.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						4
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Конкурентные цены
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Получайте первоклассный сервис без премиум-цены.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="16px 16px 16px 16px"
				lg-order="-2"
				lg-width="100%"
				width="100%"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			/>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				width="40%"
				height="100%"
			>
				<Box
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 60% 0px"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						width="100%"
						right={0}
						min-height="100%"
						top="auto"
						left={0}
						bottom="0px"
						src="https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-2.jpg?v=2024-06-14T10:50:58.116Z"
						object-fit="cover"
						position="absolute"
						display="block"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-2.jpg?v=2024-06-14T10%3A50%3A58.116Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-2.jpg?v=2024-06-14T10%3A50%3A58.116Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-2.jpg?v=2024-06-14T10%3A50%3A58.116Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-2.jpg?v=2024-06-14T10%3A50%3A58.116Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-2.jpg?v=2024-06-14T10%3A50%3A58.116Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-2.jpg?v=2024-06-14T10%3A50%3A58.116Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-2.jpg?v=2024-06-14T10%3A50%3A58.116Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				width="60%"
				lg-width="100%"
				lg-order="-1"
			>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="100%"
					flex-direction="column"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="8px 16px 8px 16px"
				/>
				<Box
					align-items="flex-start"
					lg-width="100%"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					padding="16px 16px 16px 16px"
					display="flex"
					sm-margin="0px 0px 0px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL1"
						font="--base"
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						md-text-align="left"
					>
						У PureClean технология обеспечивает совершенство уборки. Мы используем новейшие технологии автомойки, включая системы бесконтактной мойки, которые уменьшают риск царапин и повреждений вашего автомобиля. Наши системы регенерации воды экономят воду и помогают защитить окружающую среду, делая каждую стирку экологическим выбором.
					</Text>
				</Box>
				<Box
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="16px 16px 16px 16px"
					display="flex"
					lg-align-items="center"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					lg-padding="0px 16px 16px 16px"
				>
					<Text
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL1"
						font="--base"
						md-text-align="left"
					>
						Безопасность вашего автомобиля является нашим главным приоритетом. Наши объекты контролируются круглосуточно и без выходных, чтобы обеспечить максимальную безопасность вашего автомобиля, пока он находится под нашим наблюдением. Мы также придерживаемся строгих стандартов безопасности, чтобы поддерживать безопасную среду как для вашего автомобиля, так и для нашей команды.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text4" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="link4" />
			<Override slot="link3" />
			<Override slot="text5" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac377f0cb1d0025f631c9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});